(function ($, root) {
  // jQuery(document).on('ready', function($){

  /* ------------------------------------------------------------ */
  /* APPLY CHEVRON TO DESKTOP AND MOBILE MENU-ITEMS WITH CHILDREN */
  /* ------------------------------------------------------------ */
  var desktopMenuWithChildren;

  //DESKTOP MENU W/ CHILDREN
  desktopMenuWithChildren = $("#main-nav > li.menu-item-has-children");
  $.each(desktopMenuWithChildren, function (index, element) {
    var parentNavText = element.querySelector("a").textContent;
    var buttonEl =
      "<button class='subtoggle fas fa-sort-down'><span class='sr-only'>Toggle submenu for " +
      parentNavText +
      "</span></button>";
    $(buttonEl).insertAfter($(element).find("a").eq(0));
    $(element).attr("aria-expanded", "false");
  });

  /* --------------------------- */
  /* HANDLE DESKTOP ACCESIBILITY */
  /* --------------------------- */

  // TOGGLE SUBMENU ARIA ATTRIBUTES ON CLICK
  $("button.subtoggle").on("click", function (e) {
    var clickedButton = e.target;
    var parentNav = $(clickedButton).closest("li.menu-item-has-children");
    // if ("true" == $(parentNav).attr('aria-expanded')) {
    //     hideOtherSubmenus(e) ;
    // } else {
    toggleSubmenu(e);
    // }
  });
  $("li.menu-item-has-children>a").on("focus", function (e) {
    var focusedEl = e.target;
    $(focusedEl)
      .closest("li.menu-item-has-children")
      .find("button.subtoggle")
      .trigger("click");
  });

  function hideOtherSubmenus(e) {
    var clickedButton = e.target;
    var containingNav = $(clickedButton).parents("nav").eq(0);
    $(containingNav)
      .find("ul.submenu, li.menu-item-has-children")
      .attr("aria-expanded", "false");
    // $(containingNav).find('ul.submenu').attr('aria-expanded', "false");
    $(containingNav).find("ul.sub-menu li a").attr("tabindex", "-1");
  }

  // var mobileMql = window.matchMedia("(min-width: 1199px)");
  // function toggleSubmenu(e) {
  //   if (mobileMql.matches) {
  //     var clickedButton = e.target;
  //     var parentNav = $(clickedButton).closest('li.menu-item-has-children');
  //     if ("true" == $(parentNav).attr('aria-expanded')) {
  //         $(parentNav).attr('aria-expanded', "false");
  //         $(parentNav).find('ul.sub-menu').attr('aria-expanded', "false");
  //         $(parentNav).find('ul.sub-menu li a').attr('tabindex','-1');
  //     } else {
  //         $(parentNav).attr('aria-expanded', "true");
  //         $(parentNav).find('ul.sub-menu').attr('aria-expanded', "true");
  //         $(parentNav).find('li.menu-item a').eq(0).focus();
  //         $(parentNav).find('ul.sub-menu li a').removeAttr('tabindex');
  //     }
  //   }
  // }

  /* SET ARIA EXAPANDED FOR SUBMENU WHEN CHILDREN IN FOCUS */
  $("li.menu-item-has-children ul.sub-menu").on("mouseover focusin", function (
    e
  ) {
    $(e.target).attr("aria-expanded", "true");
  });

  //HIDE SUBMENU ON FOCUSOUT
  $("li.menu-item-has-children ul.sub-menu li").on("blur", function (e) {
    $(e.target)
      .parents("li.menu-item-has-children")
      .eq(0)
      .attr("aria-expanded", "false");
    $(e.target).parents("ul.sub-menu").eq(0).attr("aria-expanded", "false");
    $(e.target)
      .parents("ul.sub-menu")
      .eq(0)
      .find("li a")
      .attr("tabindex", "-1");
    // if ($(e.target).parents("#mobile-nav").length > 0) {
    //   $(e.target)
    //     .parents("li.menu-item-has-children")
    //     .eq(0)
    //     .find("button.subtoggle")
    //     .eq(0)
    //     .toggleClass("fa-rotate-180");
    //   $(e.target).parents(".sub-menu").eq(0).stop(true).slideToggle(500);
    // }
  });
  // UPDATE ARIA EXPANDED AND REMOVE ABILITY TO TAB TO SUBMENU ITEMS
  $("li.menu-item-has-children ul.sub-menu").attr("aria-expanded", "false");
  $(
    "li.menu-item-has-children ul.sub-menu, li.menu-item-has-children ul.sub-menu li a"
  ).attr("tabindex", "-1");

  /* ---------------------- */
  /* MOBILE TOGGLE SUBMENU */
  /* ---------------------- */

  //TOGGLE MOBILE SUBMENU APPEARANCE
  // $(".menu-item.menu-item-has-children .fa-sort-down").on("click", function (
  //   e
  // ) {
  //   // $(this).siblings('.sub-menu').stop(true).slideToggle(500);
  //   $(this).toggleClass("fa-rotate-180");
  // });

  // CLOSE MOBILE MENU WHEN SCREEN IS ABOVE MD SIZE
  var mobileMql = window.matchMedia("(min-width: 1199px)");
  $(window).on("resize", function () {
    if (mobileMql.matches) {
      //HIDE MOBILE MENU
      $(
        ".main-nav, header > .overlay, #desktop-menu, button.hamburger"
      ).removeClass("is-active");

      const mobileIcon = document.querySelector("button.hamburger");
      const mobileMenu = document.querySelector(".mobile-header-menu");
      document.querySelector("button#close").classList.add("d-none");
      mobileMenu.classList.add("d-left");

      // $('.menu-mobile-row').hide();

      // RESTORE STYLE AND SCROLLING FOR DESKTOP VIEW
      // $('header.header.clear').css('padding-bottom','');
      $("#desktop-menu, body").removeAttr("style");
      // $('body').css({});
    }
  });

  /**
   * SELECT2 Implementation
   */
  $(document).ready(function () {
    $(".lang-list").select2({
      minimumResultsForSearch: -1,
      debug: true,
    });
  });
  $(".lang-link").on("select2:select", function (e) {
    window.open(e.params.data.id, "_self");
  });
})(jQuery, this);
// });// End jQuery
