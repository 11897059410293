(function ($, root) {
  $(document).ready(function () {
    /**
     *  Testimonials Slider - using slick Slider (vertical)
     *  */
    $(".slider-testimonials").slick({
      arrows: true,
      autoplay: true,
      accessibility: true,
      speed: 800,
      autoplaySpeed: 15000,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      vertical: true,
      centerPadding: "0",
      cssEase: "ease-in-out",
      appendArrows: ".slider-testimonials-nav",
      prevArrow:
        '<button class="prev" aria-label="previous"><span class="fas fa-chevron-up"></span><span class="sr-only">Previous</span></button>',
      nextArrow:
        '<button class="next" aria-label="next"><span class="fas fa-chevron-down"></span><span class="sr-only">Next</span></button>',
      respondTo: "window",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            infinite: true,
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4500,
            vertical: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            dots: true,
            arrows: false,
            vertical: false,
          },
        },
      ],
    });

    /**
     * Stop autoplay on slick arrow press
     */
    $(".slider-testimonials-nav button.slick-arrow").click(function () {
      $(".slider-testimonials").slick(
        "slickSetOption",
        {
          autoplay: false,
        },
        true
      );
    });

    // Set Slider Control position and Show background
    $(".slider-testimonials").on("setPosition", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      let slider = slick.$slider;
      // Possibly toggle this for single element to show only
      // setVisibleHeightTest(slider);
      let gridWidth = $(
        ".slider-testimonials .slick-current .testimonial-content"
      ).innerWidth();
      let colWidth = $(".slider-testimonials .testimonial-text").innerWidth();
      let leftSpacing = (gridWidth - colWidth) / 2 - 38 / 2;

      let gridHeight = $(
        ".slider-testimonials .slick-current .testimonial-content"
      ).outerHeight(true);
      let buttonHeight = $(
        ".testimonials-outter .slider-testimonials-nav button"
      )
        .eq(0)
        .outerHeight();
      let sliderControlsHeight = gridHeight + buttonHeight * 2;
      $(".testimonials-outter .slider-testimonials-nav").css({
        left: leftSpacing + "px",
        height: sliderControlsHeight + "px",
      });

      $(
        ".slider-testimonials .slick-current .testimonial-content .background"
      ).animate(
        {
          opacity: 1,
        },
        {
          duration: 450,
          easing: "swing",
          queue: false,
        }
      );
    });

    //Fade out background
    $(".slider-testimonials").on("beforeChange", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      let target =
        ".slider-testimonials [data-slick-index='" + currentSlide + "']";
      $(target).find(".background").animate(
        {
          opacity: 0,
        },
        {
          duration: 450,
          easing: "swing",
          queue: false,
        }
      );
    });

    /**
     *  Wide Slider - using slick Slider
     *  */

    $(".wide-slider").slick({
      arrows: false,
      autoplay: true,
      speed: 800,
      autoplaySpeed: 800,
      accessibility: true,
      infinite: true,
      slidesToShow: 8,
      variableWidth: true,
      slidesToScroll: 1,
      adaptiveHeight: true,
      // mobileFirst: true,
      // centerPadding: "0",
      // respondTo       : 'min',
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4,
            centerMode: true,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 2,
            centerMode: true,
          },
        },
      ],
    });

    /**
     * Banner Slider
     */
    $(".banner-slider").slick({
      dots: true,
      arrows: false,
      autoplay: false,
      speed: 800,
      accessibility: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      // variableWidth: false,
      // adaptiveHeight: true,
      // mobileFirst: true,
      centerPadding: "0",
      // respondTo       : 'min',
      // responsive     : [
      //     {
      //         breakpoint : 991,
      //         settings   : {
      //             slidesToShow   : 5,
      //         }
      //     },
      //     {
      //         breakpoint : 767,
      //         settings   : {
      //             slidesToShow   : 4,
      //             centerMode: true,
      //         }
      //     },
      //     {
      //         breakpoint : 575,
      //         settings   : {
      //             slidesToShow   : 2,
      //             centerMode: true,
      //         }
      //     }
      // ]
    });
    $(".banner-slider").on("setPosition", function () {
      $(".slider-area").css("opacity", "1");
    });

    /**
     * Timeline - Moments Slider
     */
    $(".timeline .moments-slider").slick({
      dots: false,
      arrows: true,
      autoplay: false,
      // speed           : 800,
      accessibility: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      appendArrows: ".moments-nav",
      prevArrow:
        "<button type='button' class='slick-prev'><span class='fas fa-chevron-up'><span class='sr-only'>Previous</span></button>",
      nextArrow:
        "<button type='button' class='slick-next'><span class='fas fa-chevron-down'></span><span class='sr-only'>Next</span></button>",
      variableWidth: false,
      // mobileFirst: true,
      centerPadding: "0px",
      centerMode: true,
      respondTo: "window",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            vertical: false,
            slidesToShow: 1,
            arrows: true,
          },
        },
      ],
    });

    /**
     * Hide arrows on scroll, use before change because its a state before the changed
     */
    $(".timeline .moments-slider").on("beforeChange", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      if (nextSlide === 0) {
        $(".timeline .slick-prev").css("opacity", "0");
        $(".timeline .slick-prev").css("visibility", "hidden");
      } else {
        $(".timeline .slick-prev").css("opacity", "1");
        $(".timeline .slick-prev").css("visibility", "visible");
      }
      if (nextSlide === slick.slideCount - 1) {
        $(".timeline .slick-next").css("visibility", "hidden");
        $(".timeline .slick-next").css("opacity", "0");
      } else {
        $(".timeline .slick-next").css("visibility", "visible");
        $(".timeline .slick-next").css("opacity", "1");
      }
    });

    /**
     * Reset visible height of the slider to accomomdate the button placement
     */
    $(".timeline .moments-slider").on("setPosition", function (
      event,
      slick,
      currentSlide,
      nextSlide
    ) {
      let slider = slick.$slider;
      setVisibleHeight(slider);

      //   $(".timeline .moments-slider .slick-current .moment-wrapper")
      //     .animate(
      //       {
      //           // here 1
      //         opacity: 1,
      //       },
      //       {
      //         duration: 0,
      //         easing: "swing",
      //         queue: false,
      //       }
      //     )
      //     .removeClass("sliding");
      $(
        ".timeline .moments-slider .slick-current  .moment-wrapper + .green-tick"
      )
        .delay(50)
        .animate(
          {
            opacity: 1,
          },
          {
            duration: 0,
            easing: "linear",
            queue: false,
          }
        );

      if ($(window).innerWidth() > 991.98) {
        let dots = $(".timeline")
          .eq(0)
          .find(
            ".moments-slider .slick-slide.slick-active:not(.slick-current) .green-dot"
          );
        let firstDot = $(dots[0]).offset().top;
        let lastDot = $(dots[1]).offset().top;
        let dotDist = lastDot - firstDot;
        // console.log(firstDot, lastDot)
        $(".timeline-thread").css({
          height: dotDist + "px",
        });
      }
    });
    //Fade out background
    // $(".timeline .moments-slider").on("beforeChange", function (
    //   event,
    //   slick,
    //   currentSlide,
    //   nextSlide
    // ) {
    //   let target =
    //     ".timeline .moments-slider [data-slick-index='" + currentSlide + "']";

    //   $(target)
    //     .find(".moment-wrapper")
    //     .animate(
    //       {
    //         opacity: 0.5,
    //       },
    //       {
    //         duration: 250,
    //         easing: "linear",
    //         queue: true,
    //       }
    //     )
    //     .addClass("sliding");
    //   $(target).find(".moment-wrapper + .green-tick").delay(500).animate(
    //     {
    //       opacity: 0,
    //     },
    //     {
    //       duration: 350,
    //       easing: "swing",
    //       queue: true,
    //     }
    //   );
    // });

    /***
     * Gallery Salon - Feature
     */
    $(".feature-media-wrapper").slick({
      dots: false,
      arrows: false,
      autoplay: false,
      fade: true,
      accessibility: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: false,
      asNavFor: ".gallery-thumbs-wrapper",
      // appendArrows : ".moments-nav",
      // prevArrow          : "<button type='button' class='slick-prev'><span class='fas fa-chevron-up'><span class='sr-only'>Previous</span></button>",
      // nextArrow          : "<button type='button' class='slick-next'><span class='fas fa-chevron-down'></span><span class='sr-only'>Next</span></button>",
      // variableWidth: false,
      adaptiveHeight: true,
      // mobileFirst: true,
      // centerPadding: "0px",
      // centerMode: true,
      // respondTo       : 'window',
      // responsive     : [
      //     {
      //         breakpoint : 991,
      //         settings   : {
      //             vertical : false,
      //             slidesToShow   :1,
      //             arrows: true,

      //         }
      //     },
      // ]
    });
    $(".gallery-thumbs-wrapper").slick({
      dots: false,
      arrows: true,
      autoplay: false,
      // speed           : 800,
      accessibility: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      vertical: false,
      asNavFor: ".feature-media-wrapper",
      // appendArrows : ".moments-nav",
      prevArrow:
        "<button type='button' class='slick-prev'><span class='fas fa-arrow-left'><span class='sr-only'>Previous</span></button>",
      nextArrow:
        "<button type='button' class='slick-next'><span class='fas fa-arrow-right'></span><span class='sr-only'>Next</span></button>",
      // variableWidth: true,
      adaptiveHeight: true,
      // mobileFirst: true,
      centerPadding: "0px",
      centerMode: true,
      focusOnSelect: true,
      respondTo: "min",
      // responsive     : [
      //     {
      //         breakpoint : 991,
      //         settings   : {
      //             vertical : false,
      //             slidesToShow   :1,
      //             arrows: true,

      //         }
      //     },
      // ]
    });

    /***
     * Testimonials Alternative layout
     * Waste Warriors Modal trigger in wasteWarriorsModal.js
     */
    $(".testimonial-alt-slider").slick({
      dots: false,
      arrows: true,
      autoplay: false,
      // fade           : true,
      accessibility: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: false,
      // asNavFor: '.social-slider',
      appendArrows: ".testimonial-alt-nav",
      prevArrow:
        "<button type='button' class='slick-prev'><span class='fas fa-chevron-left'><span class='sr-only'>Previous</span></button>",
      nextArrow:
        "<button type='button' class='slick-next'><span class='fas fa-chevron-right'></span><span class='sr-only'>Next</span></button>",
      variableWidth: true,
      adaptiveHeight: true,
      centerPadding: "0px",
      centerMode: true,
      // mobileFirst: true,
      // centerPadding: "0px",
      // centerMode: true,
      respondTo: "window",
      responsive: [
        {
          breakpoint: 1000,
          // breakpoint : 767,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
          },
        },
      ],
    });
    $(".testimonial-alt-slider").on("init setPosition", function (e) {
      setMarginLeft();
    });
    function setMarginLeft() {
      let containerWidth = $(".container").outerWidth();
      let viewportWidth = $(window).innerWidth();

      let marginLeft = (viewportWidth - containerWidth) / 2;
      marginLeft += 30; // add width of left padding
      // if (marginLeft > 180) { marginLeft = 180;}
      $(".testimonial-alt-slider-section .titles-controls").css(
        "padding-left",
        marginLeft
      );
      //   console.log(containerWidth, viewportWidth);
    }

    /***
     * Social Share
     */
    $(".social-slider").slick({
      dots: true,
      arrows: true,
      autoplay: false,
      // fade           : true,
      accessibility: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: false,
      // asNavFor: '.social-slider',
      appendArrows: ".social-nav",
      prevArrow:
        "<button type='button' class='slick-prev'><span class='fas fa-chevron-left'><span class='sr-only'>Previous</span></button>",
      nextArrow:
        "<button type='button' class='slick-next'><span class='fas fa-chevron-right'></span><span class='sr-only'>Next</span></button>",
      variableWidth: true,
      adaptiveHeight: false,
      // mobileFirst: true,
      centerPadding: "0px",
      centerMode: true,
      respondTo: "window",
      responsive: [
        {
          breakpoint: 574,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
          },
        },
      ],
    });
  });

  // 20240916 - how-to_2024
  function howto2024_slider() {
    $('.howto-2024-section .entries').slick({
      dots: true,
      arrows: false,
      autoplay: false,
      // fade           : true,
      accessibility: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      vertical: false,
      variableWidth: false,
      adaptiveHeight: false,
      // mobileFirst: true,
      respondTo: "window",
    });
  }
  if(window.innerWidth < 991) {
    howto2024_slider();
  }
  $(window).resize(function(e){
    if(window.innerWidth < 991) {
        if(!$('.howto-2024-section .entries').hasClass('slick-initialized')){
          howto2024_slider();
        }

    }else{
        if($('.howto-2024-section .entries').hasClass('slick-initialized')){
            $('.howto-2024-section .entries').slick('unslick');
        }
    }
  });

  function setVisibleHeight(slider) {
    /// Reset Height
    let sliderInner = slider["0"].offsetParent;
    let activeSlides = $(sliderInner).find(".slick-active");
    let totalVisibleHeight = 0;
    activeSlides.each(function (index, element) {
      totalVisibleHeight += $(element).outerHeight();
    });

    $(slider).find(".slick-list").css("height", totalVisibleHeight);
  }
  function setVisibleHeightTest(slider) {
    var maxHeight = -1;
    $(".slick-slide").each(function () {
      if ($(this).height() > maxHeight) {
        maxHeight = $(this).height();
      }
    });
    $(".slick-slide").each(function () {
      if ($(this).height() < maxHeight) {
        $(this).css(
          "margin",
          Math.ceil((maxHeight - $(this).height()) / 2) + "px 0"
        );
      }
    });
  }
})(jQuery, this);
