(function ($, root) {
  var impact = [];

  $(function() {
    $ = jQuery;
    setOutputValues(0);
    addPounds();

    $("#impact .td").each(function (index, val) {
      let dataTarget = Object.keys(val.dataset)[0];
      let dataVal = $(val).data(dataTarget);
      impact[index] = new Odometer({
        el: val,
        value: 0.0,

        // Any option (other than auto and selector) can be passed in here
        format: "(,ddd).dd",
        //   theme: 'minimal'
      });
    });
    addPounds();
  });
  $("#impact").on("submit", function (e) {
    e.preventDefault();
    calculateValues();
    showCTA();
  });

  $('#impact [type="submit"]').on("click", function (e) {
    e.preventDefault();
    calculateValues();
  });

  function showCTA() {
    let ctaWrapper = $(".impact-calc .cta-wrapper");
    if (ctaWrapper.length != 0) {
      ctaWrapper.show();
    }
  }
  function calculateValues() {
    let multiplier = $("#clients").val();
    if (multiplier.trim() == "") return; // check to make sure a value was entered
    setOutputValues(multiplier);
    showCTA();
  }

  function setOutputValues(multiplier) {
    var dataAttrs = $('form[name="impact"] .td');
    var dataVals = $('form[name="impact"] .td');
    var dataNames = $('form[name="impact"] .th');
    var dataValsArr = Array.from(dataVals);
    var dataNamesArr = Array.from(dataNames);

    const baseclients = 215;
    const avgboxprocessed = 87;

    const statics = {
      color: 105,
      metal: 256,
      hair: 309,
      paper: 471,
      plastic: 374,
      sui: 118,
    };

    dataValsArr.forEach(function (val, index) {
      // let cellName = dataNamesArr[index].textContent.toLowerCase();
      // if (cellName == "single use items") {
      //   cellName = "sui";
      // }

      let currentVal = statics[dataAttrs[index].getAttribute("type")];
      // let currentVal = Object.entries(statics).find((x) => {
      //   cellName.includes(x[0]);
      // });

      /**
       * formula of life
       */
      // let z = the multiplier (ratio from the data given in the sheet)
      // https://drive.google.com/drive/search?q=gcs
      // z = x * 87 / 215
      // x = z * 215 / 87
      let input = multiplier;
      let x = (currentVal * avgboxprocessed) / baseclients;
      let z = (parseInt(input) * x) / avgboxprocessed;
      if (z) {
        $(val).text(Math.round(z));
      }
    });
  }

  function addPounds() {
    if ($('form[name="impact"] .td .pounds').length <= 0) {
      //Add lb to the value when done
      $('form[name="impact"] .td').each(function (index, element) {
        $(element).append('<span class="pounds">&nbsp;lbs</span>');
      });
    }
  }
})(jQuery, this);
