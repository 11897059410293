document.addEventListener("DOMContentLoaded", function () {
  var el = document.querySelector(".waste .counter");

  if (el === null) return;

  var currentDate = new Date();
  var timeDiffSeconds = Math.round(currentDate.getTime() * 0.0001) / 100;
  let currentstat = timeDiffSeconds;

  let somemagicalnumber = 6511346;

  od = new Odometer({
    el: el,
    value: somemagicalnumber,
    theme: 'minimal',
    // Any option (other than auto and selector) can be passed in here
    format: "(,ddd)",
    auto: false
    //   theme: 'minimal'
  });

  var currentDate = new Date();
  var timeDiffSeconds = currentDate.getTime() * 0.001;
  // var newVal; //(currentstat < newVal) ? newVal : currentstat;
  currentstat = ThemeJS.Variables.gcsWasteTotal;
  // setInterval(function () {

  //   // OLD CODE from Live site, using values entered in their ACF fields
  //   var currentDate = new Date();
  //   var initialDate = new Date('05/11/2018'); //grabbed value from ACF field
  //   var increaseUnit = 0.035; // This is the value from the existing ACF field
  //   var timeDiffSeconds = (currentDate - initialDate)/1000;
  //   var increment = timeDiffSeconds * increaseUnit;
  //   //console.log(currentstat + increment);
  //   var currentstat2 = parseInt((currentstat + increment) * 100);
  //   //console.log(currentstat2);
  //   //currentstat = parseInt(currentstat * 100) + increment;
  //   currentstat2 = (currentstat2 / 100).toFixed(2);
  //   // console.log(currentstat2);
  //   // END OLD CODE

  //   newVal = currentstat2;
  //   // console.log(newVal.toFixed(2));
  //   el.innerHTML = newVal;
  // }, 1000); // Old site has increment of 1 second

  el.innerHTML = currentstat;

  //Add lb to the v.alue when done
  let poundsUnit = el.querySelector(".pounds");
  if (poundsUnit === null) {
    let pounds = document.createElement("span");
    pounds.classList.add("pounds");
    pounds.textContent = " lbs";
    el.append(pounds);
  }
});
