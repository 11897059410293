document.addEventListener('DOMContentLoaded', function(){
    if (document.querySelector('#teamModal') == null) return;
    $ = jQuery;
    $('#teamModal').on('show.bs.modal', function(e){
        let targetMember = e.relatedTarget;
        let modalContents = $(targetMember).find('template')[0].content;
        let profileImgSrc = $(targetMember).find('.profile-wrapper img').attr('src');
        let positionTitle = $(targetMember).find('p.position').html();
        let memberName = $(targetMember).find('h3.name').html();
        let bio = $(modalContents).find(".bio").html();
        let qna = $(modalContents).find("ul.qna-wrapper").html();
        $('#teamModal ul.qna-wrapper, #teamModal .bio').html("");
        
        $('#teamModal .profile-img').attr('src', profileImgSrc );
        $('#teamModal .bio').html(bio);
        $('#teamModal ul.qna-wrapper').html(qna);
        $('#teamModal .profile-title .small').html(positionTitle);
        $('#teamModal .profile-title .big').html(memberName);
        
        if ($(modalContents).find("ul.qna-wrapper").html().trim().length == 0) {
            // $('#teamModal .modal-body').hide();
            $('#teamModal .modal-dialog').css({
                'max-height' : '90vh',
                'height' : 'initial',
            });
            
        } else {
            $('#teamModal .modal-body').show();
            $('#teamModal .modal-dialog').css({
                'height' : '90vh',
                'max-height' : 'initial',
            });
        }
        // console.log($(modalContents).find("ul.qna-wrapper").html().trim().length == 0);
    });
});