(function ($, root) {
  var impactRevenue = [];

  $(function() {
    $ = jQuery;
    setOutputValuesIR(0);
    addPoundsIR();

    $("#impact-revenue .td").each(function (index, val) {
      let dataTarget = Object.keys(val.dataset)[0];
      let dataVal = $(val).data(dataTarget);
      impactRevenue[index] = new Odometer({
        el: val,
        value: 0.0,

        // Any option (other than auto and selector) can be passed in here
        format: "(,ddd).dd",
        //   theme: 'minimal'
      });
    });
    $(".revenues .revenue .r-value.dynamic").each(function (index, val) {
      let dataTarget = Object.keys(val.dataset)[0];
      let dataVal = $(val).data(dataTarget);
      impactRevenue[index] = new Odometer({
        el: val,
        value: 0.0,

        // Any option (other than auto and selector) can be passed in here
        format: "(,ddd).dd",
        //   theme: 'minimal'
      });
    });
    setOutputValuesIR(300);
    addPoundsIR();
  });
  $("#impact-revenue #cr-range").on("change, input", function (e) {
    e.preventDefault();
    $( '#cr-val').html( $( this ).val() );
    calculateValuesIR();
  });

  function calculateValuesIR() {
    let multiplier = $("#cr-range").val();
    if (multiplier.trim() == "") return; // check to make sure a value was entered
    setOutputValuesIR(multiplier);
  }

  function setOutputValuesIR(multiplier) {
    var dataAttrs = $('form[name="impact-revenue"] .td');
    var dataVals = $('form[name="impact-revenue"] .td');
    var dataNames = $('form[name="impact-revenue"] .th');
    var dataValsArr = Array.from(dataVals);
    var dataNamesArr = Array.from(dataNames);

    const baseclients = 215;
    const avgboxprocessed = 87;

    const statics = {
      color: 105,
      metal: 256,
      hair: 309,
      paper: 471,
      plastic: 374,
      sui: 118,
    };

    const revenues = {
      monthly: 2.50,
      cost: 1.65
    }

    dataValsArr.forEach(function (val, index) {
      // let cellName = dataNamesArr[index].textContent.toLowerCase();
      // if (cellName == "single use items") {
      //   cellName = "sui";
      // }

      let currentVal = statics[dataAttrs[index].getAttribute("type")];
      // let currentVal = Object.entries(statics).find((x) => {
      //   cellName.includes(x[0]);
      // });

      /**
       * formula of life
       */
      // let z = the multiplier (ratio from the data given in the sheet)
      // https://drive.google.com/drive/search?q=gcs
      // z = x * 87 / 215
      // x = z * 215 / 87
      let input = multiplier;
      let x = (currentVal * avgboxprocessed) / baseclients;
      let z = (parseInt(input) * x) / avgboxprocessed;
      if (z) {
        $(val).text(Math.round(z));
      }
    });

    var rvMonthly = Math.round(revenues.monthly * parseInt(multiplier));
    var rvCost = Math.round(revenues.cost * parseInt(multiplier));
    var rvRevenue = (rvMonthly - rvCost) * 12;
    // if (0 == parseInt(multiplier)) {
    //   $( "#rv-esf" ).text( ((2.50 * 100) / 100).toFixed(2));
    // }
    $( "#rv-monthly" ).text( ((rvMonthly * 100) / 100).toFixed(2));
    $( "#rv-cost" ).text( ((rvCost  * 100) / 100).toFixed(2));
    $( "#rv-revenue" ).text( ((rvRevenue  * 100) / 100).toFixed(2));
  }

  // add pounds (lbs) and currency ($)
  function addPoundsIR() {
    if ($('form[name="impact-revenue"] .td .pounds').length <= 0) {
      //Add lb to the value when done
      $('form[name="impact-revenue"] .td').each(function (index, element) {
        $(element).append('<span class="pounds">&nbsp;lbs</span>');
      });
    }
    if ($('.revenues .revenue .r-value.dynamic .currency').length <= 0) {
      //Add lb to the value when done
      $('.revenues .revenue .r-value.dynamic').each(function (index, element) {
        $(element).prepend('<span class="currency">$</span>');
      });
    }
  }
})(jQuery, this);
