(function ( $, root ) {

    $(document).ready(function(){
        
        var isModifying = true;
        $(window).on('resize load', function(){
            if (!isModifying) {
                isModifying = true;
                clearTimeout(sbiModify);
            }
            
            if(isModifying === true) {
                isModifying = false;
                setTimeout(sbiModify,1000);
            }
        });

        /**
         * Modify the Smash Balloon Instagram plugin to seem responsive
         */
        function sbiModify () {
            
            let itemsToHideArray;
            let $sbiContainer =  $("#sb_instagram");

            $('.sbi_item').show();

            //Remove the class governing the columns
            $sbiContainer.removeClass(function (index, className) {
                return (className.match (/(^|\s)sbi_col_\S+/g) || []).join(' ');
            });

            if (window.innerWidth < 767) {
                //Set 2 columns
                $sbiContainer.addClass('sbi_col_2');
                itemsToHideArray = $sbiContainer.find('.sbi_item').slice(4);
                $(itemsToHideArray).each(function(index, value){
                    $(value).hide();        
                });
            } 
            else if (window.innerWidth < 991) {
                //Set 4 columns
                $sbiContainer.addClass('sbi_col_4');
                itemsToHideArray = $sbiContainer.find('.sbi_item').slice(8);
                $(itemsToHideArray).each(function(index, value){
                    $(value).hide();        
                });
            } else {
                //Set 5 columns
                $sbiContainer.addClass('sbi_col_5');
            }
            $(window).trigger('resize');
        }
    });

})( jQuery, this );