(function ($, root) {

    let hbspt_confirmation = false;
    let hbspt_client_count = false;
    let hbspt_multiplier = false;

    $(document).ready( function() {
        console.log("init");
        $(".hbspt-form").each( function() {
            let identifier = 'hubspot_' + (new Date()).getTime() + Math.random().toString(36).substr(2);
            $(this).addClass( identifier );

            if ( $(this).hasClass("estimator") ) {
                let THIS = $(this);
                let parent = $(this).parents('.form-wysiwyg')[0];
                let formId = 'cc738f06-ac99-49f7-9fbd-90f1c286a433';

                if ( typeof wpml_xdomain_data !== "undefined" && wpml_xdomain_data.current_lang === 'fr' ) {
                    formId = '47dd79f9-44b8-4477-b6fc-45d9bf4e7f98';
                }

                hbspt.forms.create({
                    portalId: "4660159",
                    formId: formId,
                    target: "." + identifier,
                    onFormSubmit: function() {
                        let form = $("form", THIS)[0];
                        let state = form.querySelector('[name=state_provice]').value;
                        let count = form.querySelector('[name=estimated_monthly_client_count]').value;
                        
                        hbspt_estimate_calc( count, state );
                        
                        let confirmation_message_to_copy;
                
                        if ( hbspt_confirmation == 'boutique' ) {
                            confirmation_message_to_copy = parent.querySelector('.full-circle-to-copy');
                        } else {
                            let first_number = (hbspt_client_count * 2).toFixed(2);
                            let second_number = (hbspt_client_count / hbspt_multiplier).toFixed(2);
                            let third_number = (first_number - second_number).toFixed(2);
                            let forth_number = (third_number * 12).toFixed(2);

                            console.log( parent, parent.querySelector('.calc_first_number') );
                
                            let calc_first_number = parent.querySelector('.calc_first_number');
                            calc_first_number.innerText = calc_first_number.textContent = first_number;
                
                            let calc_pricing_from_region = parent.querySelector('.calc_pricing_from_region');
                            calc_pricing_from_region.innerText = calc_pricing_from_region.textContent = hbspt_multiplier;
                
                            let calc_second_number = parent.querySelector('.calc_second_number');
                            calc_second_number.innerText = calc_first_number.textContent = second_number;
                
                            let calc_third_number = parent.querySelector('.calc_third_number');
                            calc_third_number.innerText = calc_third_number.textContent = third_number;
                
                            let calc_forth_number = parent.querySelector('.calc_forth_number');
                            calc_forth_number.innerText = calc_forth_number.textContent = forth_number;
                            
                            
                            confirmation_message_to_copy = parent.querySelector('.calc-price-form-to-copy');
                        }
                
                        console.log(confirmation_message_to_copy.innerText);
                
                        form.querySelector('[name=pricing_calculator_results]').value = confirmation_message_to_copy.innerText;
                    },
                    onFormSubmitted: function() {
                        let confirmation_message_to_copy;
                        let confirmation_message = parent.querySelector('.hbspt-form');
                
                        if ( hbspt_confirmation == 'boutique' ) {
                            confirmation_message_to_copy = parent.querySelector('.full-circle-to-copy');
                        } else {
                            confirmation_message_to_copy = parent.querySelector('.calc-price-form-to-copy');
                        }
                
                        confirmation_message.innerHTML = confirmation_message_to_copy.innerHTML;
                        confirmation_message.className += " calc-price-gform-confirmation";
                    }
                });

            } else {
                $(this).addClass( identifier );

                if ( $(this).data("form") ) {
                    hbspt.forms.create({
                        portalId: "4660159",
                        formId: $(this).data("form"),
                        target: "." + identifier,
                    });
                }
            }
        });
    });

    function hbspt_estimate_calc( number_of_clients, state_province ) {
        number_of_clients = parseInt(number_of_clients);

        const IL = [
            "AL", "AR", "CO", "CT", "DE", "FL", "GA", "IA", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS", "NC", "ND", "NE", "NH", "NJ", "NM", "NY", "OH", "OK", "PA", "RI", "SC", "SD", "TN", "TX", "VA", "VT", "WI", "WV",
        ];
        const NW = ["AK", "AZ", "CA", "HI", "ID", "MT", "NV", "OR", "UT", "WA", "WY"];
        const BC = ["BC", "YK"];
        const AB = ["AB", "NT", "SK", "YT"];
        const ON = ["MB", "ON"];
        const QC = ["NB", "NF", "NS", "PE", "QC", "NL"];

        if (number_of_clients < 250) {
            setConfirmationSettings('boutique', number_of_clients, 0);
            return;
        }

        if (number_of_clients >= 250 && number_of_clients < 400) {
                if (IL.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.35);
                } else if (NW.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.35);
                } else if (BC.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.45);
                } else if (AB.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.45);
                } else if (ON.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.35);
                } else if (QC.includes(state_province)) {
                    setConfirmationSettings('full', number_of_clients, 1.35);
                }
        }

        if (number_of_clients >= 400 && number_of_clients < 800) {
            setConfirmationSettings('full', number_of_clients, 1.25);
        }

        if (number_of_clients >= 800 && number_of_clients < 1200) {
            setConfirmationSettings('full', number_of_clients, 1.15);
        }

        if (number_of_clients >= 1200) {
            if (IL.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 0.95);
            } else if (NW.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 0.95);
            } else if (BC.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 1.05);
            } else if (AB.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 1.05);
            } else if (ON.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 0.95);
            } else if (QC.includes(state_province)) {
                setConfirmationSettings('full', number_of_clients, 0.95);
            }
        }
    }

    function setConfirmationSettings(type, count, multiplier) {
        hbspt_confirmation = type;
        hbspt_client_count = count;
        hbspt_multiplier   = multiplier;
    }

})(jQuery, this);