window.addEventListener("DOMContentLoaded", () => {
// jQuery(document).ready(function($){
    var tabs = document.querySelectorAll('.tabbed-cards [role=tab], .team-section [role="tab"]');
    var tabList = document.querySelector('.tabbed-cards [role=tablist], .team-section [role="tablist"]'); // Add a click event handler to each tab

    tabs.forEach(function (tab) {
      tab.addEventListener("click", changeTabs, true);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    // tabList.addEventListener("keydown", e => {
    //   // Move right
    //   if (e.keyCode === 39 || e.keyCode === 37) {
    //     tabs[tabFocus].setAttribute("tabindex", -1);
    //     if (e.keyCode === 39) {
    //       tabFocus++;
    //       // If we're at the end, go to the start
    //       if (tabFocus >= tabs.length) {
    //         tabFocus = 0;
    //       }
    //       // Move left
    //     } else if (e.keyCode === 37) {
    //       tabFocus--;
    //       // If we're at the start, move to the end
    //       if (tabFocus < 0) {
    //         tabFocus = tabs.length - 1;
    //       }
    //     }

    //     tabs[tabFocus].setAttribute("tabindex", 0);
    //     tabs[tabFocus].focus();
    //   }
    // });
  });

  function changeTabs(e) {
    // Assign target element as the Button even if text inside is clicked
    var targetElem;
    var bubbledPath = e.path;

    // console.log(e);
    if (e.target.className == "button-tab") {
        targetElem = e.currentTarget;
    } else {
        targetElem = e.target.closest('.button-tab');
    }
    // console.log(e, targetElem);
    // if ("BUTTON" != e.target.nodename) {

    //         for (var i = 0; i < bubbledPath.length; i++){

    //             if ("BUTTON" == bubbledPath[i].nodeName) {
    //                 targetElem = bubbledPath[i];
    //                 break;
    //             }
    //         }
    // } else {
    //     targetElem = e.target;
    // }

    var tabSection = targetElem.closest('section');
    var tablist = tabSection.querySelector('[role="tablist"]');
    var tabpanels = tabSection.querySelectorAll('[role="tabpanel"]');

    // Remove all current selected tabs
    tablist.querySelectorAll('[aria-selected="true"]').forEach(function (t) {
      return t.setAttribute("aria-selected", false);
    });

    // Set this tab as selected
    targetElem.setAttribute("aria-selected", true);
    targetElem.setAttribute("aria-expanded", true);

    // Hide all tab panels
    tabpanels //   .querySelectorAll('[role="tabpanel"]');
    .forEach(function (p) {
      return p.setAttribute("hidden", true);
    });

    // Show the selected panel
    var targetPanelId = '#' + targetElem.getAttribute('aria-controls');
    tabSection.querySelector(targetPanelId).removeAttribute("hidden");
  }
