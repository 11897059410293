document.addEventListener('DOMContentLoaded', function(){
    if (document.querySelector('#testimonialAltModal') == null) return;
    $ = jQuery;
    /**
     * Get testimonial data and fill modal
     * @param {HTMLElement} targetBtn The button that was clicked nearest to the testimonial content
     */
    function fillModalData(targetBtn) {
        let testimonialContext = $(targetBtn).closest('.testimonial-content');
        // $("#testimonialAltModal").modal('show');

        //get Original HTML
        let profileImgSrc = $(testimonialContext).find('.avatar-container img').attr('src');
        let profileImgAlt = $(testimonialContext).find('.avatar-container img').attr('alt');
        let ratingHtml = $(testimonialContext).find('.stars-name .rating').html();
        let ratingAriaLabel = $(testimonialContext).find('.stars-name .rating').attr('aria-label');
        let quoteNameHtml = $(testimonialContext).find('.stars-name .quote-name').html();
        let quoteHtml = $(testimonialContext).find('.testimonial-text blockquote').html();


        // place in Modal
        let testimonialAltModal = $("#testimonialAltModal");
        testimonialAltModal.find(".avatar-container img").attr('src', profileImgSrc);
        testimonialAltModal.find(".avatar-container img").attr('alt', profileImgAlt);
        testimonialAltModal.find("#testimonialAltModalLabel").html(quoteNameHtml);
        testimonialAltModal.find(".rating").html(ratingHtml);
        testimonialAltModal.find(".rating").attr('aria-label',ratingAriaLabel);
        testimonialAltModal.find(".modal-body .quote").html(quoteHtml);
    }

    //FILL MODAL DATA ON MODAL SHOW
    $("#testimonialAltModal").on('show.bs.modal', function(e){
        fillModalData(e.relatedTarget);
    });

    $('#testimonialAltModal').on('hidden.bs.modal', function (e) {
        // Clear data from testimonial modal
        let testimonialAltModal = $("#testimonialAltModal");
        testimonialAltModal.find(".avatar-container img").attr('src', "");
        testimonialAltModal.find(".avatar-container img").attr('alt', "");
        testimonialAltModal.find("#testimonialAltModalLabel").html("");
        testimonialAltModal.find(".rating").html("");
        testimonialAltModal.find(".rating").attr('aria-label', "");
        testimonialAltModal.find(".modal-body .quote").html("");
      })

    // FOR DEBUGGING, TRIGGER THE MODAL ON PAGE LOAD
    // $('.testimonial-alt-slider [data-slick-index="0"] button.read-more').click();

    $(".testimonial-alt-slider").on("setPosition", function (e) {
        showButtonIfOverflowed();
    });

    // Show buttons when the window size changes if height of content is too tall
    var resizeWin;
    $(window).on('resize', function(){
      if (typeof resizeWin != "undefined") {
          clearTimeout(resizeWin);
      }
        resizeWin = setTimeout(showButtonIfOverflowed, 500);
    });

    /**
    * Show Read more button if the Content is too tall
    */
    function showButtonIfOverflowed() {
        $('.testimonial-alt-slider blockquote').each(function(index,element){
            // console.log($(element).closest('[data-slick-index]').attr('data-slick-index'));
            // console.log($(element).css('overflow'));
            
            let scrollHeight = element.scrollHeight;
            let clientHeight = element.clientHeight;
            let quoteIsHidden = (scrollHeight > clientHeight);
            // console.log(quoteIsHidden);
            // console.log(scrollHeight);
            // console.log(clientHeight);
            let $btnWrapper = $(element).next('.btn-wrapper').eq(0);
            if (quoteIsHidden) {
                $btnWrapper.show();
                $btnWrapper.css({
                    "opacity" : 1
                });
            } else {
                $btnWrapper.hide();
                $btnWrapper.css({
                    "opacity" : 0
                });
            }
            
        });
    }
});