(function ($, root, undefined) {
  $(function () {
    "use strict";
    // DOM ready, take it away

    // $("a").on("click", function () {
    //   var href = $(this).attr("href");
    //   triggerEvent("a", getContent(this), href);
    // });

    $("button.calculate-impact-calc").on("click", function () {
      triggerEvent(
        "button",
        'Impact Calculator',
        window.location.href,
      );
    });

    function getContent(elem) {
      return $(elem)
        .html()
        .replace(/(<([^>]+)>)/gi, "")
        .trim();
    }

    function triggerEvent(element, content, destination_url) {
      var source_url = window.location.href;
      // console.log({
      //   event_category: "Click",
      //   event_label: content,
      //   destination_url,
      //   source_url,
      // });
      if ( typeof gtag == 'function' ) { 
        gtag("event", "Click", {
          event_category: "Click",
          event_label: content,
          destination_url,
          source_url,
        });
      }
    }
  });
})(jQuery, this);
