document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector("#wasteModal") == null) return;
  $ = jQuery;
  $("#wasteModal").on("show.bs.modal", function (e) {
    var url = document.URL;
    if (url.includes("#wasteModal-")) {
      let ze = url.split("#wasteModal-")[1];

      var x = $(`#${ze}`).html();
      //   $("#wasteModal .modal-body").html($(xyz.html).clone());
      $("#wasteModal .modal-body").html(x);
    } else {
      let targetWasteItem = e.relatedTarget;
      let modalContent = $(targetWasteItem).next("template.modal_content")[0]
        .content;
      // console.log($(modalContent).clone())
      $("#wasteModal .modal-body").html($(modalContent).clone());
    }
  });
});
