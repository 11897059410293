document.addEventListener("DOMContentLoaded", function () {
  // alert('loaded');
  let videoContainer = document.querySelectorAll(".video-container");
  if (videoContainer.length == 0) return;
  let videoContainerArray = Array.from(videoContainer);

  videoContainer.forEach(function (element, index) {
    let hoverPlay = element.querySelector("div.video-container");
    let videoType = element.dataset.videoType;
    // let hoverPlay = document.querySelector('.video-container button.play.fa-stack');
    // let videoType = document.querySelector('.video-container').dataset.videoType;
    let videoEl;
    if (videoType == "video") {
      videoEl = element.querySelector("video");
      videoEl.addEventListener("play", hideHoverPlay, false);
      videoEl.addEventListener("playing", hideHoverPlay, false);
      videoEl.addEventListener("pause", showHoverPlay, false);
      videoEl.addEventListener("seeking", showHoverPlay, false);
      videoEl.addEventListener("ended", showHoverPlay, false);
      hoverPlay.addEventListener("click", playVideo, false);
    } else if (videoType == "embed") {
      videoEl = element.querySelector("template").content;

      jQuery("div.video-container").on("click", function () {
        let container = element;
        let videoHTML = videoEl;

        container.innerHTML = "";
        container.style.backgroundImage = "";
        container.appendChild(videoHTML.cloneNode(true));
        container.querySelector(".embed-responsive").style.height = "100%";
      });
      // No longer needed, just use jQuery for full click.
      //   hoverPlay.addEventListener(
      //     "click",
      //     function () {
      //       let container = element;
      //       let videoHTML = videoEl;

      //       container.innerHTML = "";
      //       container.style.backgroundImage = "";
      //       container.appendChild(videoHTML.cloneNode(true));
      //       container.querySelector(".embed-responsive").style.height = "100%";
      //     },
      //     false
      //   );
    }
  });

  function hideHoverPlay() {
    hoverPlay.style.display = "none";
  }
  function showHoverPlay() {
    hoverPlay.style.display = "initial";
  }

  async function playVideo() {
    try {
      await videoEl.play();
    } catch (err) {
      console.log(err);
    }
  }
});
