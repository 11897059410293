jQuery(document).ready(function($) {
    let events = $('article.type-event');
    if (events.length <= 0 ) return;

    events.each(function (index, element){
        let postId = $(element).data('postid');
        let moreLink = $(element).find('a.more-link');
        if (moreLink.length <= 0) return; 
        $(moreLink).on('click', function(e){
            e.preventDefault();
            get_event_content(e.delegateTarget, postId)
        });
    });

    /**
     * Get Content
     */
    function get_event_content(target, postId) {
        // console.log(postId, target);

        let data = {
            postId : postId,
            action: 'get_event_content',
            nonce: accordions.Vars.Ajax.nonce
        }
        
        $.post(accordions.Vars.Ajax.url, data)
        .done(function(response){
            // console.log("done", response);
            let res = JSON.parse(response);
                // console.log("hit", data.post
            let extended = res.response;
            // console.log(response.response);
            $(extended).appendTo($(target).closest('.event-content'));
            $(target).remove();
            
        })
        .fail(function(response){
            console.error("Error getting content details");
        })
        // .always(function(response){
        //     console.log("always", response);
        // })
        
    }
});