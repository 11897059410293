const mobileIcon = document.querySelector("button.hamburger");
const mobileMenu = document.querySelector(".mobile-header-menu");

const resultMenuChildren = [];
var valueMenuChildren = mobileMenu.querySelectorAll(
  ".menu-item-has-children"
);
Array.prototype.push.apply(resultMenuChildren, valueMenuChildren);
const dropdownMenu = resultMenuChildren;
const mobileDropdown = resultMenuChildren;
const body = document.querySelector("body");
const main = document.querySelector("main");

const resultSections = [];
const valuesSections = document.querySelectorAll("section");
Array.prototype.push.apply(resultSections, valuesSections);
const sections = resultSections;
const footer = document.querySelector("footer");

let mobileIconOpen = () => {
  if (mobileIcon.classList.length === 2) {
    mobileIcon.classList.toggle("initial");
  } else {
    if (window.innerWidth > 600) {
      mobileIcon.classList.add("show-gcs-mobile-menu");
    }
    mobileIcon.classList.toggle("initial");
  }
  mobileIcon.classList.toggle("change");
  mobileMenu.classList.toggle("d-left");
};

// TOGGLE MOBILE MENU BUTTON AND MENU APPEARING
jQuery("button#close").click(function () {
  mobileMenu.classList.toggle("d-left");
});
jQuery("button.hamburger, button#close").click(function () {
  // $(this).toggleClass('is-active');
  jQuery(".main-nav, header > .overlay").toggleClass("is-active");
  document.querySelector("button#close").classList.toggle("d-none");
  body.classList.toggle("overflow-hidden");

  // if ($(".main-nav").hasClass("is-active")) {
  //   // $('header.header.clear').css('padding-bottom','0');
  //   $("#desktop-menu").css({
  //     "overflow-y": "scroll",
  //     display: "block",
  //     "padding-bottom": "80px", //because of iphone bottom navbar
  //   });
  //   $("body").css({
  //     "overflow-y": "hidden",
  //     position: "fixed",
  //   });
  //   $(".menu-item-has-children").attr("aria-expanded", "true");
  // } else {
  //   // $('header.header.clear').css('padding-bottom','');

  //   $("#desktop-menu").css({
  //     "overflow-y": "",
  //   });
  //   $("body").css({
  //     "overflow-y": "scroll",
  //     position: "",
  //   });
  //   $(".menu-item-has-children").attr("aria-expanded", "false");
  // }
});

let mobileMenuChevronOpen = () => {
  mobileDropdown.map((dropdown) => {
    const resultdropdown = [];
    const valuesdropdown = dropdown.childNodes;
    Array.prototype.push.apply(resultdropdown, valuesdropdown);
    let chevron = resultdropdown.filter((chevron) => chevron.tagName === "UL");
    chevron[0].classList.add("d-none");
    let anchorElement = dropdown.childNodes[0];
    anchorElement.addEventListener("click", () => {
      let isExpanded = chevron[0].classList.toggle("d-none");

      if (isExpanded) {
        chevron[0].classList.remove("d-flex");

        resultdropdown[0].childNodes[1].classList.remove("fa-caret-up");
        resultdropdown[0].childNodes[1].classList.add("fa-caret-down");
      } else {
        resultdropdown[0].childNodes[1].classList.remove("fa-caret-down");
        resultdropdown[0].childNodes[1].classList.add("fa-caret-up");

        chevron[0].classList.add("d-flex");
      }
    });
  });
};

let addDropdownChevron = () => {
  dropdownMenu.forEach((item) => {
    let dropdownChevronEl = document.createElement("span");
    dropdownChevronEl.classList.add(
      "fas",
      "fa-caret-down",
      "mobile-menu-chevron"
    );

    const resultItems = [];
    const valueItems = item.childNodes;
    Array.prototype.push.apply(resultItems, valueItems);
    let anchor = resultItems.filter(
      (node) => node instanceof HTMLAnchorElement
    );
    anchor[0].appendChild(dropdownChevronEl);
  });
};

document.addEventListener("DOMContentLoaded", () => {
  addDropdownChevron();
  mobileMenuChevronOpen();
  mobileIcon.addEventListener("click", mobileIconOpen);
});
